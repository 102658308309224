import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataService } from 'src/data.service';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  constructor(private modalService: NgbModal, private dataService: DataService, private router: Router) {}
  Usuario: any;
  ImagendePerfil: any;
  getUsuario(){
    const id = localStorage.getItem('UsuarioID')
    return this.dataService.Getusuarios(id)
    .subscribe( data => {
      console.log(data)
      this.Usuario = data
    })
  }
  clearStorage(){
		console.log("se ha deslogueado")
    localStorage.removeItem('UsuarioID');
    localStorage.removeItem('tipos_id');
    localStorage.removeItem('TipoUsuarios')
    }
  getUsuarioID(){
    const id = localStorage.getItem('UsuarioID')
    return id;
  }
  // This is for Notifications
  goToPage(pageName:string){
		this.router.navigate([`${pageName}`]);
  }
  
  ngOnInit(){
    const id = localStorage.getItem('UsuarioID')
    this.getUsuario();

    
  }
  ngAfterViewInit() {
    
  }
}
