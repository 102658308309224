import { RouteInfo } from './sidebar.metadata';
export const ROUTESUsuarios: RouteInfo[] = [
      {
        path: '/modulos/inicio',
        title: 'Esquelas',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Generar-esquelas',
        title: 'Generar Esquela',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Perfil',
        title: 'Perfil',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Categorias',
        title: 'Productos',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Tiendas',
        title: 'Empresas',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
];
export const ROUTESAdmin: RouteInfo[] = [
  
  {
    path: '',
    title: 'Esquelas',
    icon: '',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/modulos/inicio',
        title: 'Todas las Esquelas',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Generar-esquelas',
        title: 'Generar Esquela',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Validar-Esquela',
        title: 'validar Esquela',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/EsquelasTipos',
        title: 'Esquelas Tipos',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
    ]
  },
  {
    path: '',
    title: 'Usuarios',
    icon: '',
    class: 'has-arrow',
    ddclass: '',
    extralink: false,
    submenu: [
      {
        path: '/modulos/Perfil',
        title: 'Perfil',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      },
      {
        path: '/modulos/Gestion-Usuarios',
        title: 'Usuarios',
        icon: '',
        class: '',
        ddclass: '',
        extralink: false,
        submenu: []
      }
    ]
  },
  //Gestion-Usuarios
  
  {
  path: '/modulos/Generar-Admin',
  title: 'Generar Usuario',
  icon: '',
  class: '',
  ddclass: '',
  extralink: false,
  submenu: []
  },
  {
    path: '/modulos/Categorias',
    title: 'Productos',
    icon: '',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/modulos/Tiendas',
    title: 'Empresas',
    icon: '',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  }
];
export const ROUTESEmpresa: RouteInfo[] = [
  {
    path: '/modulos/Perfil-Empresa',
    title: 'Perfil Empresa',
    icon: '',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/modulos/GenerarProducto',
    title: 'Generar producto',
    icon: '',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/modulos/EstadisticasVentas',
    title: 'Mis Ventas',
    icon: '',
    class: '',
    ddclass: '',
    extralink: false,
    submenu: []
  },

]
