import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import 'rxjs/Rx';
import { catchError } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { id } from '@swimlane/ngx-datatable/release/utils';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

Request
@Injectable({
  providedIn: 'root'
})

export class DataService {
  constructor(private _http: HttpClient) { }

  
//---------------------------------------------------------------------------------------
Esquelasgetall = "https://funerarias.herokuapp.com/api/esquelas/get_all"
EsquelasgetID = "https://funerarias.herokuapp.com/api/esquelas/get_"
EsquelasNew = "https://funerarias.herokuapp.com/api/esquelas/add"
EsquelasImgFondo = "https://funerarias.herokuapp.com/api/esquelas_img/get_"
EsquelasImgFondoAll = "https://funerarias.herokuapp.com/api/esquelas_img/get_all"
EsquelaUpdate = "https://funerarias.herokuapp.com/api/esquelas/update_"
public GetEsquelasImagenFondo(): Observable<any> {
  return this._http.get(this.EsquelasImgFondoAll, this.httpOptions)
}
public GetAllEsquelas(): Observable<any> {
  return this._http.get(this.Esquelasgetall, this.httpOptions)
}
public GetAllEsquelaInfo(id: any): Observable<any> {
  return this._http.get(this.EsquelasgetID + id, this.httpOptions)
}
public getEsquelaImgFondo(id): Observable<any> {
  return this._http.get(this.EsquelasImgFondo + id, this.httpOptions)
}
public NewEsquela(titulo, descripcion, img, fondo_img_id, funeraria_empresas_id, funeraria_direccion, cementerio_empresa_id,
fecha_ini, fecha_fin, hora_ini, hora_fin, fecha_ini_entierro, hora_ini_entierro, hora_fin_entierro, nombre, apellido, estado,
esquelas_textos_id, esquelas_tipos_id, usuarios_id): Observable<any> {
  let formulario = {
        "titulo": titulo,
        "descripcion": descripcion,
        "img": img,
        "fondo_img_id": fondo_img_id,
        "funeraria_empresas_id": funeraria_empresas_id,
        "funeraria_direccion": funeraria_direccion,
        "cementerio_empresa_id": cementerio_empresa_id,
        "fecha_ini": fecha_ini,
        "fecha_fin": fecha_fin,
        "hora_ini": hora_ini,
        "hora_fin": hora_fin,
        "fecha_ini_entierro": fecha_ini_entierro,
        "hora_ini_entierro": hora_ini_entierro,
        "hora_fin_entierro": hora_fin_entierro,
        "nombre": nombre,
        "apellido": apellido,
        "estado": estado,
        "esquelas_textos_id": esquelas_textos_id,
        "esquelas_tipos_id": esquelas_tipos_id,
        "usuarios_id": usuarios_id
  }
  return this._http.post(this.EsquelasNew, formulario, this.httpOptions)
}
public UpdateEsquela(id, titulo, descripcion, img, fondo_img_id, funeraria_empresas_id, funeraria_direccion, cementerio_empresa_id,
  fecha_ini, fecha_fin, hora_ini, hora_fin, fecha_ini_entierro, hora_ini_entierro, hora_fin_entierro, nombre, apellido, estado,
  esquelas_textos_id, esquelas_tipos_id, usuarios_id): Observable<any> {
    let formulario = {
          "titulo": titulo,
          "descripcion": descripcion,
          "img": img,
          "fondo_img_id": fondo_img_id,
          "funeraria_empresas_id": funeraria_empresas_id,
          "funeraria_direccion": funeraria_direccion,
          "cementerio_empresa_id": cementerio_empresa_id,
          "fecha_ini": fecha_ini,
          "fecha_fin": fecha_fin,
          "hora_ini": hora_ini,
          "hora_fin": hora_fin,
          "fecha_ini_entierro": fecha_ini_entierro,
          "hora_ini_entierro": hora_ini_entierro,
          "hora_fin_entierro": hora_fin_entierro,
          "nombre": nombre,
          "apellido": apellido,
          "estado": estado,
          "esquelas_textos_id": esquelas_textos_id,
          "esquelas_tipos_id": esquelas_tipos_id,
          "usuarios_id": usuarios_id
    }
    return this._http.put(this.EsquelaUpdate + id, formulario, this.httpOptions)
  }
  public UpdateEsquelaEstado(estado, id): Observable<any> {
      let formulario = {
            "estado": estado,
      }
      return this._http.put(this.EsquelaUpdate + id, formulario, this.httpOptions)
    }
//---------------------------------------------------------------------------------------
EmpresasGetAll = "https://funerarias.herokuapp.com/api/empresas/get_all"
public GetAllEmpresas(): Observable<any> {
  return this._http.get(this.EmpresasGetAll, this.httpOptions)
}

VentasEsquelasPorUsuario = "https://funerarias.herokuapp.com/api/usuarios/get_ventas_esquelas_"
VentasEmpresasPorUsuarios = "https://funerarias.herokuapp.com/api/usuarios/get_ventas_empresas_"
EmpresasProductosPorID = "https://funerarias.herokuapp.com/api/empresas_productos/get_"
 
GetVentasEsquelasID(id): Observable<any> {
  return this._http.get(this.VentasEsquelasPorUsuario+ id, this.httpOptions)
}
GetVentasEmpresasID(id): Observable<any> {
  return this._http.get(this.VentasEmpresasPorUsuarios+ id, this.httpOptions)
}
GetEmpresasProductos(id): Observable<any> {
  return this._http.get(this.EmpresasProductosPorID+ id, this.httpOptions)
}

EsquelasTiposGetAll = "https://funerarias.herokuapp.com/api/esquelas_tipos/get_all"
EsquelasPorEstado = "https://funerarias.herokuapp.com/api/esquelas/esquelas_estados"
public getAllEsquelasPorEstado(estado): Observable<any> {
  let formulario = {
    "estados": estado
  }
  return this._http.put(this.EsquelasPorEstado, formulario, this.httpOptions)
}

public GetAllEsquelasTipos(): Observable<any> {
  return this._http.get(this.EsquelasTiposGetAll, this.httpOptions)
}

//---------------------------------------------------------------------------------------
CategoriasGetAll = "https://funerarias.herokuapp.com/api/empresas_categorias/get_all";
public GetAllCategorias(): Observable<any> {
  return this._http.get(this.CategoriasGetAll, this.httpOptions)
}

//---------------------------------------------------------------------------------------
CategoriasProductosGetAll = "https://funerarias.herokuapp.com/api/empresas_productos_categorias/get_all";
ProductosGetAll = "https://funerarias.herokuapp.com/api/empresas_productos/get_all"
ProductosAdd = "https://funerarias.herokuapp.com/api/empresas_productos/add";
ProductosUpdate = "https://funerarias.herokuapp.com/api/empresas_productos/update_";
ProductosDelete = "";
public GetAllProductos(): Observable<any> {
  return this._http.get(this.CategoriasProductosGetAll, this.httpOptions)
}
public AddProducto(titulo, descripcion, img, img1, img2, precio, estado, empresas_id, productos_categorias_id, tipos_id, cantidad): Observable<any> {
  let formulario = {
    "titulo": titulo,
    "descripcion": descripcion,
    "img": img,
    "img1": img1,
    "img2": img2,
    "precio": precio,
    "estado": estado,
    "empresas_id": empresas_id,
    "productos_categorias_id": productos_categorias_id,
    "tipos_id": tipos_id,
    "cantidad": cantidad
  }
  console.log(formulario)
  return this._http.post(this.ProductosAdd, formulario, this.httpOptions)
}
public UpdateProducto(id, titulo, descripcion, img, img1, img2, precio, estado, productos_categorias_id, tipos_id, cantidad): Observable<any> {
  let formulario = {
    "titulo": titulo,
    "descripcion": descripcion,
    "img": img,
    "img1": img1,
    "img2": img2,
    "precio": precio,
    "estado": estado,
    "productos_categorias_id": productos_categorias_id,
    "tipos_id": tipos_id,
    "cantidad": cantidad
  }
  console.log(formulario)
  return this._http.put(this.ProductosUpdate + id, formulario, this.httpOptions)
}
public AllProductos(): Observable<any> {
  return this._http.get(this.ProductosGetAll, this.httpOptions)
}

//---------------------------------------------------------------------------------------
TiendasGetAll = "https://funerarias.herokuapp.com/api/empresas/get_all"
public GetAllTiendas(): Observable<any> {
  return this._http.get(this.TiendasGetAll, this.httpOptions)
}
//---------------------------------------------------------------------------------------
ProductoID = "https://funerarias.herokuapp.com/api/empresas_productos/get_"
ProductosPorCategoria = "https://funerarias.herokuapp.com/api/empresas_productos/categoria_"
NuevaCompra = "https://funerarias.herokuapp.com/api/empresas_productos_ventas/add"
EsquelasPorUsuario = "https://funerarias.herokuapp.com/api/esquelas/usuario_"
GetProductoID(id): Observable<any> {
  return this._http.get(this.ProductoID + id, this.httpOptions)
}
GetProductosPorCategoria(id): Observable<any> {
  return this._http.get(this.ProductosPorCategoria+id, this.httpOptions)
} 
public GetEsquelaPorUsuario(id): Observable<any> {
  return this._http.get(this.EsquelasPorUsuario+id, this.httpOptions)
}
public GenerarCompra(empresa, usuarios_id, direccion_entrega, texto, hora, fecha, cantidad, pago, esquela_id): Observable<any>{
  let formulario = {
    "empresas_productos_id": empresa,
    "usuarios_id": usuarios_id,
    "direccion_entrega": direccion_entrega,
    "texto": texto,
    "hora": hora,
    "fecha": fecha,
    "cantidad": cantidad,
    "estado": pago,
    "esquela_id": esquela_id
  }
  console.log(formulario)
  return this._http.post(this.NuevaCompra, formulario, this.httpOptions)
}

//---------------------------------------------------------------------------------------
empresasLogin = "https://funerarias.herokuapp.com/api/empresas/login"
public LoginEmpresa(pass, email): Observable<any>{
  let formulario = {
    "pass": pass,
    "email": email
  }
  return this._http.put(this.empresasLogin, formulario, this.httpOptions)
}
empresaAdd = "https://funerarias.herokuapp.com/api/empresas/add"
public NewEmpresaUsuario(titulo, descripcion, direccion, email, pass, telefono, logo, usuario_id, tpo_id, estado, long, lat): Observable<any>{
  let formulario = {
        "titulo": titulo,
        "descripcion": descripcion,
        "direccion": direccion,
        "email": email,
        "pass": pass,
        "telefono": telefono,
        "logo": logo,
        "usuarios_id": usuario_id,
        "tipos_id": tpo_id,
        "categorias_id": 1,
        "estado": estado,
        "long": long,
        "lat": lat
  }
  return this._http.post(this.empresaAdd, formulario, this.httpOptions)
}
empresaUpdate = "https://funerarias.herokuapp.com/api/empresas/update_"
public UpdateEmpresaUsuario(id, titulo, descripcion, direccion, email, telefono, logo, tpo_id, estado, long, lat): Observable<any>{
  let formulario = {
        "titulo": titulo,
        "descripcion": descripcion,
        "direccion": direccion,
        "email": email,
        "telefono": telefono,
        "logo": logo,
        "tipos_id": tpo_id,
        "categorias_id": 1,
        "estado": estado,
        "long": long,
        "lat": lat
  }
  return this._http.put(this.empresaUpdate + id, formulario, this.httpOptions)
}
usuariosget = "https://funerarias.herokuapp.com/api/usuarios/get_"

usuariosadd = "https://funerarias.herokuapp.com/api/usuarios/add"
usuariosUpdate = "https://funerarias.herokuapp.com/api/usuarios/update_"
empresaget = "https://funerarias.herokuapp.com/api/empresas/get_"
public getEmpresa(id): Observable<any> {
  return this._http.get(this.empresaget + id, this.httpOptions)
}
public Getusuarios(id): Observable<any> {
  return this._http.get(this.usuariosget + id, this.httpOptions)
}
public Addusuarios(nombre, apellido, email, pass, tel , direccion, img, tipos_id, empresas_Cantdiad, esquelas_Cantidad, pesame_Cantidad, estado ): Observable<any>{
  let formulario = {
    "nombre": nombre,
    "apellido": apellido,
    "email": email,
    "pass": pass,
    "tel": tel,
    "direccion": direccion,
    "img": img,
    "tipos_id": tipos_id,
    "empresas_cantidad": empresas_Cantdiad,
    "esquelas_cantidad": esquelas_Cantidad,
    "pesame_cantidad": pesame_Cantidad,
    "estado": estado
  }
  return this._http.post(this.usuariosadd, formulario, this.httpOptions)
}
public updateUsuario(id, nombre, apellido, email, tel , direccion, img): Observable<any>{
  let formulario = {
    "nombre": nombre,
    "apellido": apellido,
    "email": email,
    "tel": tel,
    "direccion": direccion,
    "img": img
  }
  return this._http.put(this.usuariosUpdate + id, formulario, this.httpOptions)
}
public Login(email, pass): Observable<any>{
  let login = "https://funerarias.herokuapp.com/api/usuarios/login"
  let formulario = {
    "email": email,
    "pass":  pass
  }
  return this._http.put(login, formulario, this.httpOptions)
}
EmpresasPorTipo = "https://funerarias.herokuapp.com/api/empresas/tipos"
public GetEmpresasPorTipo(tipos): Observable<any>{
  let formulario = {
    "tipos": tipos
  }
  return this._http.put(this.EmpresasPorTipo, formulario, this.httpOptions)
}
UsuariosTipos = "https://funerarias.herokuapp.com/api/usuarios/tipos_"
public getUsuariosPorTipos(tipo): Observable<any>{
  return this._http.get(this.UsuariosTipos + tipo, this.httpOptions)
}
UpdateUsuarioEstado = "https://funerarias.herokuapp.com/api/usuarios/update_"
public UsuarioEstadoUpdate(id, estado): Observable<any>{
  let formulario = {
    "estado": estado
  }
  return this._http.put(this.UpdateUsuarioEstado + id, formulario, this.httpOptions)
}
EsquelasMensajes = "https://funerarias.herokuapp.com/api/esquelas_mensajes_luto/w_usuarios"
public GetEsquelasMensajes(): Observable<any>{
  return this._http.get(this.EsquelasMensajes, this.httpOptions)
}
GenerarOracion = "https://funerarias.herokuapp.com/api/esquelas_mensajes_luto/add"
EliminarOracion = "https://foxylabs-eventos.herokuapp.com/api/esquelas_mensajes_luto/delete_"
public AddOracion(mensaje, esquelaID, usuarioID, estado, fechahora): Observable<any>{
  let formulario = {
    "mensaje": mensaje,
    "esquela_id": esquelaID,
    "usuario_id": usuarioID,
    "estado": estado,
    "fechahora": fechahora,
    "categoria_mensaje": 1
  }
  return this._http.post(this.GenerarOracion, formulario, this.httpOptions)
}
public deleteOracion(id): Observable<any>{
  return this._http.delete(this.EliminarOracion + id, this.httpOptions)
}

EsquelasVentas = "https://funerarias.herokuapp.com/api/esquelas_ventas_usuarios/get_all"
esquelasVentasADD = "https://funerarias.herokuapp.com/api/esquelas_ventas_usuarios/add"

public getEsquelasVentas(): Observable<any>{
  return this._http.get(this.EsquelasVentas, this.httpOptions)
}

public AddEsquelasVentas(usuarios_id, esquela_id, precio): Observable<any>{
  let formulario = {
        "usuarios_id": usuarios_id,
        "esquela_id": esquela_id,
        "precios_id": 1,
        "precio": precio,
        "membresia_id": "",
        "cantidad": 1
  }
  console.log(formulario)
  return this._http.post(this.esquelasVentasADD, formulario, this.httpOptions)
}

ProductosporEmpresa = "https://funerarias.herokuapp.com/api/empresas_productos/empresa_"
getProductosEmpresa(id_Empresa): Observable<any>{
  return this._http.get(this.ProductosporEmpresa + id_Empresa, this.httpOptions)
}

EsquelasPrecios = "https://funerarias.herokuapp.com/api/esquelas_precios/get_all"
getEsquelasPrecios(): Observable<any>{
  return this._http.get(this.EsquelasPrecios, this.httpOptions)
}
  //---------------------------------------------------------------------------------------

stripe = "https://funerarias.herokuapp.com/api/esquelas_ventas_usuarios/stripe_add"
public AddStripe(usuarios_id, esquela_id, precios_i, cantidad, email, precio, card_number, exp_month, exp_year, cvc): Observable<any>{
  let formulario = {
    usuarios_id: usuarios_id,
    esquela_id: esquela_id,
    precios_id: precios_i,
    cantidad: cantidad,
    email: email,
    precio: precio,
    card_number: card_number,
    exp_month: exp_month,
    exp_year: exp_year,
    cvc: cvc
  }
  console.log("stripe: "+formulario)
  return this._http.post(this.stripe, formulario, this.httpOptions)
}
ProductosPrecios = "https://funerarias.herokuapp.com/api/productos_precios/get_all"
getProductosPrecios(): Observable<any>{
  return this._http.get(this.ProductosPrecios, this.httpOptions)
}
EsquelasTiposAdd = "https://funerarias.herokuapp.com/api/esquelas_tipos/add"
AddEsquelasTipos(titulo, descripcion): Observable<any>{
  let fomrulario = {
    "titulo": titulo,
    "descripcion": descripcion
  }
  return this._http.post(this.EsquelasTiposAdd, fomrulario, this.httpOptions)
}
EsquelasPreciosAdd = "https://funerarias.herokuapp.com/api/esquelas_precios/add"
AddEsquelasPrecios(titulo, descripcion, precio): Observable<any>{
  let fomrulario = {
        "precio": precio,
        "titulo": titulo,
        "descripcion": descripcion
  }
  return this._http.post(this.EsquelasPreciosAdd, fomrulario, this.httpOptions)
}
EsquelasProductosAdd = "https://funerarias.herokuapp.com/api/esquelas_productos/add"
AddEsquelasProductos(titulo, descripcion): Observable<any>{
  let fomrulario = {
    "titulo": titulo,
    "descripcion": descripcion
  }
  return this._http.post(this.EsquelasProductosAdd, fomrulario, this.httpOptions)
}
EsquelasPreciosProductosAdd = "https://funerarias.herokuapp.com/api/productos_precios"
AddEsquelasPreciosProductos(esquelaProductotitulo, EsquelaPrecioID,EsquelaProductoDescripcion): Observable<any>{
  let fomrulario = {
    "producto_titulo": esquelaProductotitulo,
    "productos_descripcion": EsquelaProductoDescripcion,
    "precios_id": EsquelaPrecioID,
    "cantidad": "1"
  }
  console.log(fomrulario)
  return this._http.post(this.EsquelasPreciosProductosAdd, fomrulario, this.httpOptions)
}
EsquelasTiposDelete = "https://funerarias.herokuapp.com/api/esquelas_tipos/delete_"
EsquelasPreciosDelete = "https://funerarias.herokuapp.com/api/esquelas_precios/delete_"
EsquelasProductosDelete = "https://funerarias.herokuapp.com/api/esquelas_productos/delete_"
EsquelasPreciosProductosDelete = "https://funerarias.herokuapp.com/api/productos_precios/"
DeleteEsquelasTipos(id): Observable<any>{
  return this._http.delete(this.EsquelasTiposDelete+id, this.httpOptions)
}
DeleteEsquelasPrecios(id): Observable<any>{
  return this._http.delete(this.EsquelasPreciosDelete+id, this.httpOptions)
}
DeleteEsquelasProductos(id): Observable<any>{
  return this._http.delete(this.EsquelasProductosDelete+id, this.httpOptions)
}
DeleteEsquelasPreciosProductos(id): Observable<any>{
  return this._http.delete(this.EsquelasPreciosProductosDelete+id, this.httpOptions)
}
TiposEsquelas = "https://funerarias.herokuapp.com/api/productos_precios/get_all"
GetTiposEsquelas(): Observable<any>{
  return this._http.get(this.TiposEsquelas, this.httpOptions)
}
TipoEsquelaPrecio = "https://funerarias.herokuapp.com/api/productos_precios/";
getTipoEsquelaPrecio(id): Observable<any>{
  return this._http.get(this.TipoEsquelaPrecio+id, this.httpOptions)
}
EsquelasTiposUpdate = "https://funerarias.herokuapp.com/api/esquelas_tipos/update_"
UpdateEsquelasTipos(id, titulo, descripcion): Observable<any>{
  let fomrulario = {
    "titulo": titulo,
    "descripcion": descripcion
  }
  return this._http.put(this.EsquelasTiposUpdate+id, fomrulario, this.httpOptions)
}
EsquelasPreciosUpdate = "https://funerarias.herokuapp.com/api/esquelas_precios/update_"
UpdateEsquelasPrecios(id, titulo, descripcion, precio): Observable<any>{
  let fomrulario = {
        "precio": precio,
        "titulo": titulo,
        "descripcion": descripcion
  }
  return this._http.put(this.EsquelasPreciosUpdate+id, fomrulario, this.httpOptions)
}
EsquelasProductosUpdate = "https://funerarias.herokuapp.com/api/esquelas_productos/update_"
UpdateEsquelasProductos(id, titulo, descripcion): Observable<any>{
  let fomrulario = {
    "titulo": titulo,
    "descripcion": descripcion, 
  }
  return this._http.put(this.EsquelasProductosUpdate+id, fomrulario, this.httpOptions)
}
EsquelasPreciosProductosUpdate = "https://funerarias.herokuapp.com/api/esquelas_precios_productos/update_"
UpdateEsquelasPreciosProductos(id,EsquelaPrecioID, cantidad): Observable<any>{
  let fomrulario = {
    "precios_id": EsquelaPrecioID,
    "cantidad": cantidad
  }
  return this._http.put(this.EsquelasPreciosProductosUpdate+id, fomrulario, this.httpOptions)
}
EsquelasProductosPreciosUpdate = "https://funerarias.herokuapp.com/api/productos_precios/"
UpdateEsquelasProductosPrecios(id, titulo, descripcion, prec_id, cantidad): Observable<any>{
  let fomrulario = {
    "producto_titulo": titulo,
    "productos_descripcion": descripcion,
    "precios_id": prec_id,
    "cantidad": cantidad,
  }
  return this._http.put(this.EsquelasProductosPreciosUpdate+id, fomrulario, this.httpOptions)
}
EsquelasProductosPreciosGet = "https://funerarias.herokuapp.com/api/productos_precios"
GetAllEsquelasProductosPrecios(): Observable<any>{
  return this._http.get(this.EsquelasProductosPreciosGet, this.httpOptions)
}

  //---------------------------------------------------------------------------------------
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };
  //---------------------------------------------------------------------------------------
}