import { Component, AfterViewInit, OnInit } from '@angular/core';
import {  ROUTESAdmin, ROUTESEmpresa, ROUTESUsuarios } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/data.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService ,
  ) {}
    UsuarioData: any;
  // End open close
  ngOnInit() {
    /*let usuarioID = localStorage.getItem('UsuarioID');
    this.dataService.Getusuarios(usuarioID)
    .subscribe((res) => {
      this.UsuarioData = res;
      console.log("Usuario tipo"+this.UsuarioData.tipos_id)
    })*/
    let usuario = localStorage.getItem('TipoUsuarios');
    console.log("id usuario"+usuario)
    if(usuario == "2"){
      this.sidebarnavItems = ROUTESAdmin.filter(sidebarnavItem => sidebarnavItem);
    }else if(usuario == "1"){
      this.sidebarnavItems = ROUTESUsuarios.filter(sidebarnavItem => sidebarnavItem);
    }else if(usuario == "3"){
      this.sidebarnavItems = ROUTESEmpresa.filter(sidebarnavItem => sidebarnavItem);
    }else{
      this.sidebarnavItems = ROUTESUsuarios.filter(sidebarnavItem => sidebarnavItem);
    }
    
  }
}
